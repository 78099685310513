import logo from './logo.svg';
import './App.css';
import Routing from './Routes/Routes';
import {Box} from "@mui/material"
function App() {
  return (
    <Box  >
      <Routing/>
    </Box>
  );
}

export default App;
