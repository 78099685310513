import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Components/Login";
import DashBoard from "../Components/DashBoard";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<DashBoard />}>
      </Route>
    </Routes>
  );
};

export default Routing;
