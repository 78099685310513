import { Box } from "@mui/material";
import React from "react";
import Banner1 from "../Assets/Banner.png";
const Banner = () => {
  return (
    <Box>
      <img src={Banner1} alt="TaskManager" style={{ width: "100%" }} />
    </Box>
  );
};

export default Banner;
