import React, { useState } from "react";
import { TextField, Button, Typography, Box, Grid } from "@mui/material";
// import Aestronote from "../Assets/OIP (7).jpg"; // Import your contact image
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });

  const handleChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic to handle form submission here
    console.log("Form submitted:", formData);
  };
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 37.7749, // Replace with the desired latitude
    lng: -122.4194, // Replace with the desired longitude
  };

  return (
    <Grid container marginBottom="10px" spacing={0}>
      <Grid item xs={12}>
        <Typography
          textAlign="center"
          marginBottom="20px"
          variant="h4"
          sx={{ fontFamily: "Libre Baskerville,serif", color: "#3f51b5",marginTop:{xs:"3rem"}}}
        >
          Contact Us
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={14}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1">{/* Your text goes here */}</Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ padding: "1rem" }}>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={formData.name}
              onChange={handleChange("name")}
              required
            />
            <TextField
              label="Mobile No."
              fullWidth
              margin="normal"
              value={formData.mobile}
              onChange={handleChange("mobile")}
              required
            />
            <TextField
              label="Email ID"
              fullWidth
              margin="normal"
              type="email"
              value={formData.email}
              onChange={handleChange("email")}
              required
            />
            <TextField
              label="Message"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange("message")}
              required
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button type="submit" variant="contained"  sx={{
                background: "rgb(251,151,63)",
                 color: "#000",
                 "&:hover":{
                 backgroundColor:"#fff !important" ,
                 color:"rgb(251,151,63)"
                 }
               }}>
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
