import React from "react";
import Navbar from "./Navbar";
import Banner from "./Banner";
import { Grid, Typography, Box, Button } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import samplePdf from "../Assets/October-November 23 P Sandesh.pdf";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Gallery from "./Gallery";
import Newselter from "./Newselter";
import Footer from "./Footer";
import ContactForm from "./Contact";
const Dashboard = () => {
  const pdfList = [
    { id: 1, name: " October-November 23 P Sandesh.pdf", path: samplePdf },
    {
      id: 2,
      name: " P Sandesh Aug - Sept 2023 Final pdf.pdf",
      path: samplePdf,
    },
    { id: 3, name: " P Sandesh Final PDF May 23.pdf", path: samplePdf },
    { id: 4, name: " P Sandesh July August 22.pdf", path: samplePdf },
    { id: 5, name: " P Sandesh May June 22 Final PDF.pdf", path: samplePdf },
    { id: 6, name: " P Sandesh Nov dec 2022 F.pdf", path: samplePdf },
    {
      id: 7,
      name: " Pushkarana sadesh March April 2022 Final F.pdf",
      path: samplePdf,
    },
    {
      id: 8,
      name: " Pushkarana Sandesh Final Dec 21-Jan 22 F.pdf",
      path: samplePdf,
    },
    {
      id: 9,
      name: "  pushkarana Sandesh June July 23.pdf",
      path: samplePdf,
    },
    {
      id: 10,
      name: "   Pushkarna Sandesh Feb 2022 Final F.pdf",
      path: samplePdf,
    },
    {
      id: 11,
      name: "   Pushkarna Sandesh Final pdf Jan Feb 23.pdf",
      path: samplePdf,
    },
    {
      id: 12,
      name: " Sandesh By AArti Aug Sept 20214.pdf",
      path: samplePdf,
    },
  ];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const openPdfView = (path) => {
    window.open(path, "_blank");
  };
  return (
    <>
      <Grid container spacing={2}>
        {/* Navbar */}
        <Grid item xs={12}>
          <Navbar />
        </Grid>

        {/* Banner */}
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Banner />
        </Grid>
      </Grid>
      <Grid container >
        
        <Grid item xs={12} sx={{display:"flex", justifyContent:"space-around"}}>
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "0.9rem",
                sm: "2rem",
                md: "2.2rem",
                lg: "2.4rem",
              },
            }}
          >
            वर्ष : 60
          </Typography>
       
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "0.9rem",
                sm: "2rem",
                md: "2.2rem",
                lg: "2.4rem",
              },
            }}
          >
            अक्टूबर - नवम्बर, 2023
          </Typography>
       
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "0.9rem",
                sm: "2rem",
                md: "2.2rem",
                lg: "2.4rem",
              },
             
            }}
          >
            अंक : 10 -11
          </Typography>
        </Grid>
        
      </Grid>
      <Grid container>
        <Grid
          item
          sx={{
            display: "flex",
            width: "100%",
            height: "90vh",
            justifyContent: "center",
            alignItems: "center",
            // display: "flex",
          }}
        >
          <iframe
            src="https://intranet.mobiloitte.com/uploads/estimations/uploads/October-November_23_P_Sandesh1.pdf"
            height={"90%"}
            width={"80%"}
            // document={{
            //   url: "https://intranet.mobiloitte.com/uploads/estimations/uploads/October-November_23_P_Sandesh1.pdf",
            //   maxScale: 40,
            //   font: 40,
            // }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} mx={4.5  }>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  xs: "1.2rem",
                  sm: "2rem",
                  md: "2.2rem",
                  lg: "2.4rem",
                },
              }}
            >
              List of Pdf
            </Typography>
            <TextField
              // fullWidth
              size={matches ? "small" : "medium"}
              placeholder="Search..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid> 
      <Grid container spacing={2}>
        <Grid item lg={12} container spacing={2} mx={3} my={2}>
          {pdfList.map((pdf) => (
            <Grid
              item
              sm={6}
              xs={12}
              key={pdf.id}
              sx={{
                // marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ maxWidth:'200px'}}>{pdf.name}</Typography>
              <Button
                sx={{
                  backgroundColor: "#FF8C00",
                  color: "#000",
                  "&:hover": { backgroundColor: "#FF8C00" },
                }}
                variant="contained"
                onClick={() => openPdfView(pdf.path)}
              >
                Open PDF
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container id="home">
        <Grid item xs={12} sx={{ mx: matches ? 0 : 3, my: matches ? 0 : 2 }}>
          <Home />
        </Grid>
      </Grid>
      <Grid container id="about">
        <Grid item xs={12} sx={{ mx: matches ? 0 : 3, my: matches ? 0 : 2 }}>
          <About />
        </Grid>
      </Grid>

      <Grid container id="gallery">
        <Grid item xs={12} sx={{ mx: matches ? 0 : 3, my: matches ? 0 : 2 }}>
          <Gallery />
        </Grid>
      </Grid>

      <Grid container id="newsletter">
        <Grid item xs={12} sx={{ mx: matches ? 0 : 3, my: matches ? 0 : 2 }}>
          <Newselter />
        </Grid>
      </Grid>

      <Grid container id="contact">
        <Grid item xs={12} sx={{ mx: matches ? 0 : 3, my: matches ? 0 : 2 }}>
          <ContactForm />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{marginTop:"2rem"}} >
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
