import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { text: "Home", path: "home" },
    { text: "About", path: "about" },
    { text: "Contact", path: "contact" },
    { text: "Gallery", path: "gallery" },
    { text: "Newsletter", path: "newsletter" },
  ];

  const drawerContent = (
    <Box sx={{ textAlign: "center", background: "rgb(251,151,63)" }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              sx={{
                textAlign: "center",
              }}
            >
              <a
                href={`#${item.path}`}
                onClick={handleDrawerToggle}
                style={{ textDecoration: "none", color: "#000" }}
              >
                <ListItemText primary={item.text} />
              </a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        sx={{
          background: "rgb(251,151,63)",
          background:
            "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
          color: "#000",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none", xs: "block" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >Pushkarana</Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.text}
                href={`#${item.path}`}
                sx={{
                  color: "#000",
                  "&:hover": {
                    color: "#FFF",
                    backgroundColor: "transparent",
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "240px",
              background: "rgb(251,151,63)",
              background:
                "radial-gradient(circle, rgba(251,151,63,1) 56%, rgba(252,213,70,1) 100%)",
              color: "#000",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Navbar;
