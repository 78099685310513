import React from "react";
import { Typography, Grid ,Box } from "@mui/material";
import Download from "../Assets/OIP (4).jpg"; // Import your contact image

const About = () => {
  const underlineTransition = {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "5%",
      height: "2px",
      backgroundColor: "rgb(251,151,63)", // Change color as needed
      transition: "width 0.3s ease-in-out", // Transition effect
    },
    "&:hover::after": {
      width: "35%", // Expand underline on hover
      "@media (min-width:600px)": {
        width: "19%", // Adjust for different screen sizes if needed
      },
      "@media (min-width:960px)": {
        width: "23%", // Adjust for different screen sizes if needed
      },
      "@media (min-width:1280px)": {
        width: "19%", // Adjust for different screen sizes if needed
      },
    },
  };
  return (
    <>
      <Grid container >
        <Grid item xs={12}>
          <Typography
            textAlign="center"
            marginBottom="20px"
            variant="h4"
            sx={{ fontFamily: "Libre Baskerville,serif", color: "#3f51b5",marginTop:{xs:"3rem"}}}
          >
            About Us
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            order: { xs: 2, md: 1 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "80%", height: "95%" ,marginLeft:{md:"-5rem", xs:"0rem"} ,boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.9)", borderRadius: "0.5rem"}}>
            <img
              src={Download}
              alt="Aestronote"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "0.5rem",
             
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ order: { xs: 1, md: 2 } }}>
          <Box sx={{width:"100%", height:"100%" ,marginLeft:{md:"-2rem", xs:"0rem"}}}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Libre Baskerville,serif",
              marginLeft: "1rem",
              marginTop: "2rem",
              ...underlineTransition,
            }}
          >
            
            What We Do
          </Typography>
          <Typography variant="body1" sx={{ padding: 2, textAlign: "justify" }}>
            Hinduism is one of the world's oldest religions, often regarded as a
            way of life or a cultural identity as much as it is a religious
            belief system. It originated in the Indian subcontinent and is
            diverse, encompassing a wide spectrum of beliefs, rituals,
            practices, and philosophies.n
          </Typography>
          <Typography variant="body1" sx={{ padding: 2, textAlign: "justify" }}>
            Hinduism is one of the world's oldest religions, often regarded as a
            way of life or a cultural identity as much as it is a religious
            belief system. It originated in the Indian subcontinent and is
            diverse, encompassing a wide spectrum of beliefs, rituals,
            practices, and philosophies.n
            Hinduism is one of the world's oldest religions, often regarded as a
            way of life or a cultural identity as much as it is a religious
            belief system. It originated in the Indian subcontinent and is
            diverse, encompassing a wide spectrum of beliefs, rituals,
            practices, and philosophies.n
          </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
