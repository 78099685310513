import React from "react";
import { useNavigate } from "react-router-dom";
const Login = () => {
  let history = useNavigate();
  return (
    <>
      <div style={{ color: "black" }}>Login</div>
      <button onClick={() => history("/dashboard")}>Task Manager</button>
    </>
  );
};

export default Login;
